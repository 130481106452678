<template>
    <div>
        <ToolsNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
            <h1>Tools</h1>
            <h4>CDI stands ahead connecting products to our customers through the use of technology</h4>
            <img :src="`${$cdiVars.BASE_URL}/images/v3/cdi_tools2.jpg`" class="img-responsive img_content_right">
            <ul class='list_content'>
                <li>Territory Map</li>
                <li>Credit Application</li>
                <li>Duct Calculator</li>
                <li>Field Measurement</li>
                <li>Installation Instructions</li>
                <li>Transit Map</li>
                <li>Mobile App</li>
            </ul>
            <p>These tools have been designed to assist you with the various steps involved in ordering your curb. If you have difficulty with any of the steps or tools, please give us a call to assist you. 1-888-234-7001.</p>
        </main>
    </div>
</template>

<script>
    import ToolsNav from '@/components/content/ToolsNav.vue'
    export default {
        name: "Tools",
        components: {
            ToolsNav,
        },
        created() {
            this.$appStore.setTitle(['CDI Curb Adapter Tools']);
        },
    }
</script>

<style scoped>

</style>